const countries = [
  { code: 'AF', name: 'Afghanistan' },
  { code: 'AL', name: 'Albanië' },
  { code: 'DZ', name: 'Algerije' },
  { code: 'AS', name: 'Amerikaans-Samoa' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AG', name: 'Antigua en Barbuda' },
  { code: 'AR', name: 'Argentinië' },
  { code: 'AM', name: 'Armenië' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AU', name: 'Australië' },
  { code: 'AT', name: 'Oostenrijk' },
  { code: 'AZ', name: 'Azerbeidzjan' },
  { code: 'BS', name: "Bahama's" },
  { code: 'BH', name: 'Bahrein' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Wit-Rusland' },
  { code: 'BE', name: 'België' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BO', name: 'Bolivië' },
  { code: 'BA', name: 'Bosnië-Herzegovina' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BV', name: 'Bouvet Eiland' },
  { code: 'BR', name: 'Brazilië' },
  { code: 'IO', name: 'Brits Indische oceaan' },
  { code: 'BN', name: 'Brunei Darussalam' },
  { code: 'BG', name: 'Bulgarije' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'KH', name: 'Cambodja' },
  { code: 'CM', name: 'Kameroen' },
  { code: 'CA', name: 'Canada' },
  { code: 'CV', name: 'Kaapverdië' },
  { code: 'KY', name: 'Kaaimaneilanden' },
  { code: 'CF', name: 'Centraal-Afrikaanse Republiek' },
  { code: 'TD', name: 'Tsjaad' },
  { code: 'CL', name: 'Chili' },
  { code: 'CN', name: 'China' },
  { code: 'CX', name: 'Christmaseiland' },
  { code: 'CC', name: 'Cocoseilanden' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoren' },
  { code: 'CG', name: 'Congo, Volksrepubliek' },
  { code: 'CD', name: 'Congo, Democratische Republiek' },
  { code: 'CK', name: 'Cookeilanden' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CI', name: 'Ivoorkust' },
  { code: 'HR', name: 'Kroatië' },
  { code: 'CU', name: 'Cuba' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Tsjechië' },
  { code: 'DK', name: 'Denemarken' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominicaanse Republiek' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypte' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatoriaal-Guinea' },
  { code: 'ER', name: 'Eritrea' },
  { code: 'EE', name: 'Estland' },
  { code: 'ET', name: 'Ethiopië' },
  { code: 'FK', name: 'Falklandeilanden' },
  { code: 'FO', name: 'Faeröer' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'Frankrijk' },
  { code: 'GF', name: 'Frans-Guyana' },
  { code: 'PF', name: 'Frans-Polynesië' },
  { code: 'TF', name: 'Franse Zuidelijke Gebieden' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgië' },
  { code: 'DE', name: 'Duitsland' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Griekenland' },
  { code: 'GL', name: 'Groenland' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinee-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haïti' },
  { code: 'HM', name: 'Heard en McDonaldeilanden' },
  { code: 'VA', name: 'Heilige Stoel' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hongarije' },
  { code: 'IS', name: 'IJsland' },
  { code: 'IN', name: 'India' },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IR', name: 'Iran' },
  { code: 'IQ', name: 'Irak' },
  { code: 'IE', name: 'Ierland' },
  { code: 'IL', name: 'Israël' },
  { code: 'IT', name: 'Italië' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'JO', name: 'Jordanië' },
  { code: 'KZ', name: 'Kazachstan' },
  { code: 'KE', name: 'Kenia' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KP', name: 'Noord-Korea' },
  { code: 'KR', name: 'Zuid-Korea' },
  { code: 'KW', name: 'Koeweit' },
  { code: 'KG', name: 'Kirgizstan' },
  { code: 'LA', name: 'Laos' },
  { code: 'LV', name: 'Letland' },
  { code: 'LB', name: 'Libanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libië' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Litouwen' },
  { code: 'LU', name: 'Luxemburg' },
  { code: 'MO', name: 'Macao' },
  { code: 'MG', name: 'Madagaskar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Maleisië' },
  { code: 'MV', name: 'Maldiven' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshalleilanden' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritanië' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'MX', name: 'Mexico' },
  { code: 'FM', name: 'Micronesië, Federale Staten' },
  { code: 'MD', name: 'Moldavië' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolië' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MA', name: 'Marokko' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibië' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Nederland' },
  { code: 'NC', name: 'Nieuw-Caledonië' },
  { code: 'NZ', name: 'Nieuw-Zeeland' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NU', name: 'Niue' },
  { code: 'NF', name: 'Norfolk' },
  { code: 'MP', name: 'Noordelijke Marianen' },
  { code: 'MK', name: 'Noord-Macedonië' },
  { code: 'NO', name: 'Noorwegen' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PS', name: 'Palestina' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papoea-Nieuw-Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Filipijnen' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PL', name: 'Polen' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Roemenië' },
  { code: 'RU', name: 'Rusland' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'SH', name: 'Sint-Helena' },
  { code: 'KN', name: 'Saint Kitts en Nevis' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'PM', name: 'Saint-Pierre en Miquelon' },
  { code: 'VC', name: 'Saint Vincent en de Grenadines' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'São Tomé en Principe' },
  { code: 'SA', name: 'Saudi-Arabië' },
  { code: 'SN', name: 'Senegal' },
  { code: 'SC', name: 'Seychellen' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SK', name: 'Slowakije' },
  { code: 'SI', name: 'Slovenië' },
  { code: 'SB', name: 'Salomonseilanden' },
  { code: 'SO', name: 'Somalië' },
  { code: 'ZA', name: 'Zuid-Afrika' },
  { code: 'GS', name: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden' },
  { code: 'ES', name: 'Spanje' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SD', name: 'Soedan' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SJ', name: 'Spitsbergen en Jan Mayen' },
  { code: 'SZ', name: 'Ngwane, Koninkrijk Swaziland' },
  { code: 'SE', name: 'Zweden' },
  { code: 'CH', name: 'Zwitserland' },
  { code: 'SY', name: 'Syrië' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'TJ', name: 'Tadzjikistan' },
  { code: 'TZ', name: 'Tanzania, Verenigde Republiek' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TL', name: 'Timor Leste' },
  { code: 'TG', name: 'Togo' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad en Tobago' },
  { code: 'TN', name: 'Tunesië' },
  { code: 'TR', name: 'Turkije' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TC', name: 'Turks- en Caicoseilanden' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Oeganda' },
  { code: 'UA', name: 'Oekraïne' },
  { code: 'AE', name: 'Verenigde Arabische Emiraten' },
  { code: 'GB', name: 'Verenigd Koninkrijk' },
  { code: 'GB-SCT', name: 'Schotland' },
  { code: 'GB-ENG', name: 'Engeland' },
  { code: 'GB-WLS', name: 'Wales' },
  { code: 'GB-NIR', name: 'Ierland' },
  { code: 'US', name: 'VS' },
  { code: 'UM', name: 'Ver afgelegen eilandjes van de Verenigde Staten' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Oezbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'VG', name: 'Maagdeneilanden, Britse' },
  { code: 'VI', name: 'Maagdeneilanden, Amerikaanse' },
  { code: 'WF', name: 'Wallis en Futuna' },
  { code: 'EH', name: 'Westelijke Sahara' },
  { code: 'YE', name: 'Jemen' },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe' },
  { code: 'AX', name: 'Åland' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius en Saba' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'IM', name: 'Man Eiland' },
  { code: 'JE', name: 'Jersey' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'BL', name: 'Saint Barthélemy' },
  { code: 'MF', name: 'Sint-Maarten (Frans deel)' },
  { code: 'RS', name: 'Servië' },
  { code: 'SX', name: 'Sint Maarten (Nederlands deel)' },
  { code: 'SS', name: 'Zuid-Soedan' },
  { code: 'XK', name: 'Kosovo' },
];

export default countries;
