import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { IoIosCart, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { MdLocalShipping } from 'react-icons/md';
import { useCart } from '../context/CartContext';

function AddToBasketButton({ product, pdp }: {product: Product, pdp?: boolean}) {
  const { addItem, cart } = useCart();
  const [orderQuantity, setOrderQuantity] = useState(product.inventory.moq || 1);
  const { inventory, published } = product;

  // account for the items in the cart
  const productInCart = cart.find(({ _id }) => _id === product._id);
  const availableStock = productInCart ? inventory.stock - productInCart.quantity : inventory.stock;
  let purchasable = inventory.moq ? availableStock >= inventory.moq : availableStock >= 0;

  if (!published) purchasable = false;
  const changeQty = product.inventory.moq || 1;
  const limitedStock = availableStock < changeQty * 20;

  const changeOrderQty = (direction) => {
    switch (direction) {
      case 'increase': {
        const newQty = orderQuantity + changeQty;
        if (newQty <= availableStock) setOrderQuantity(newQty);
        break;
      }
      case 'decrease':
        if (orderQuantity > changeQty) {
          setOrderQuantity((prev) => prev - changeQty);
        }
        break;
      default:
        break;
    }
  };

  const addToCart = () => {
    addItem(product, orderQuantity);
    setOrderQuantity(changeQty);
  };

  // ordered before 14h00 are deliverd next day, excluding sunday
  const day = new Date().getDay();
  const sunday = day === 0;
  const saturday = day === 6;
  const withinOrderPeriod = !(saturday || sunday) && Date.now() < new Date().setHours(14, 0, 0);

  return (
    <>
      <div className="purchase-info">
        {purchasable ? (
          <span className="text-success">
            <IoMdCheckmarkCircleOutline color="green" size="16" className="me-2" />
            {limitedStock ? (
              <span>Nog {availableStock} op voorraad</span>
            ) : (
              <span>Op voorraad</span>
            )}
            {withinOrderPeriod && pdp && (
              <span className="d-block" id="deliveryInfo">
                <MdLocalShipping color="green" size="16" className="me-2" />
                Morgen in huis
              </span>
            )}
          </span>
        ) : (
          <span className="text-muted">Tijdelijk uitverkocht</span>
        )}
      </div>
      <div className="addToBasketButton d-flex align-items-center mt-2">
        <div className="basketQuantity">
          <span className="qty">{orderQuantity}</span>{' '}
          <div className="quantityButtons h-100 w-100">
            <button
              disabled={!purchasable || orderQuantity + changeQty > availableStock}
              type="button"
              className="w-100 h-50"
              onClick={() => changeOrderQty('increase')}
            >
              <span>+</span>
            </button>
            <button
              disabled={!purchasable || orderQuantity === changeQty}
              type="button"
              className="w-100 h-50"
              onClick={() => changeOrderQty('decrease')}
            >
              <span>-</span>
            </button>
          </div>
        </div>

        <Button color="success" className="px-1" onClick={addToCart} disabled={!purchasable}>
          <IoIosCart className="mx-1" color="white"/>
          <span className="d-none d-xl-inline-block text-white" style={{ fontSize: 13 }}>
            In winkelwagen
          </span>
        </Button>
      </div>
    </>
  );
}

export default AddToBasketButton;
