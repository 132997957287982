import React from 'react';
import Head from 'next/head';

function SEO({
  canonical,
  title = 'Deals die smaken naar meer!',
  description,
  openGraphImage: ogFromProps,
}: any) {
  if ((!canonical || canonical === '') && process.env.NODE_ENV === 'development') {
    console.error('SEO component: canonical prop is empty or inexistent');
  }
  let div;
  const locale = 'nl-be';
  let openGraphImage = ogFromProps;
  let baseUrl;

  switch (process.env.NEXT_PUBLIC_APP) {
    case 'dranken':
      div = 'Dranken.be';
      baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : 'https://dranken.be';
      if (!openGraphImage) openGraphImage = `${baseUrl}/dranken-og.jpg`;
      break;
    case 'shopdeal':
      div = 'Shopdeal';
      baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://shopdeal.be';
      if (!openGraphImage) openGraphImage = `${baseUrl}/shopdeal-og.jpg`;
      break;
    default:
      break;
  }

  return (
    <Head>
      <title>{`${div} | ${title.charAt(0).toUpperCase() + title.slice(1)}`}</title>
      {description && <meta name="description" content={description} />}
      {/* <link rel="manifest" href="/manifest.json" /> */}
      <meta
        property="og:title"
        key="og:title"
        content={`${div} | ${title.charAt(0).toUpperCase() + title.slice(1)}`}
      />
      <meta property="og:description" key="og:description" content={description} />
      <meta property="og:site_name" key="og:site_name" content={div} />
      <meta property="og:type" key="og:type" content="website" />
      {openGraphImage && <meta property="og:image" key="og:image" content={openGraphImage} />}
      {canonical && (
        <>
          <meta property="og:url" key="og:url" content={`${baseUrl}${canonical}`} />
          <link rel="canonical" href={`${baseUrl}${canonical}`} />
          <link rel="alternate" href={`${baseUrl}${canonical}`} hrefLang={locale} />
        </>
      )}
    </Head>
  );
}

export default SEO;
